<template>

    <div class="container mt-5">


        <h2 class="mb-0 mt-3 text-uppercase">Bonetype Table</h2>
        <hr />
        <div class="row">
            <div :class="dataTableClass">
                <div v-if="bonetypes.length">
                    <button v-if="isAdminAllowed || isEditorAllowed" class="btn btn-primary my-3" type="button"
                        @click="addNewBonetype">Add new data</button>
                        <div class="d-flex gap-2 align-items-center mb-3" v-if="loadData">
                            <button class="btn btn-primary btn-sm" type="button" v-if="isUserAllowedToShowButton || isEditorAllowedToShowButton || isAdminAllowed " @click="downloadExcel">Download as Excel </button>
                            <button class="btn btn-primary btn-sm" type="button" v-if="isEditorAllowedToShowButton || isUserAllowedToShowButton || isAdminAllowed" @click="downloadPDF">Download as PDF </button>
                        </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table id="dataTableSort" class="table table-striped table-bordered" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>bonetype_id</th>
                                            <th>bonetype_name</th>
                                            <th>hammas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="cursor: pointer;" v-for="(bonetype, index) in bonetypes" :key="index"
                                            valign="middle" @click="selectBonetype(bonetype)">
                                            <td>{{ bonetype.bonetype_id }}</td>
                                            <td>{{ bonetype.bonetype_name }}</td>
                                            <td>{{ bonetype.hammas }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>bonetype_id</th>
                                            <th>bonetype_name</th>
                                            <th>hammas</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4" v-else>No Data Found.</div>
            </div>
            <div :class="formTableClass" :style="{ marginTop: isAdminAllowed || isEditorAllowed ? '4.4rem' : '0' }">
                <div class="card border-top border-0 border-4 border-info">
                    <div class="card-body">
                        <div class="border p-4 rounded">
                            <div class="row mb-3">
                                <label class="col-sm-3 col-form-label formLabel">bonetype id</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" v-model="selectedBonetype.bonetype_id">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label class="col-sm-3 col-form-label formLabel">bonetype name</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" v-model="selectedBonetype.bonetype_name">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label class="col-sm-3 col-form-label formLabel">hammas</label>
                                <div class="col-sm-9">
                                    <input type="email" class="form-control" v-model="selectedBonetype.hammas">
                                </div>
                            </div>
                            <div class="row mb-3" v-if="isAdminAllowed || isEditorAllowed">
                                <div class="col-sm-9 offset-sm-3">
                                    <button v-if="!changeButton" @click="editBonetype"
                                        class="btn btn-warning btn-sm">Edit</button>
                                    <button v-if="changeButton" @click="editBonetype"
                                        class="btn btn-primary btn-sm mx-3">Save</button>
                                    <button @click="cancelBonetype" class="btn btn-danger btn-sm ms-3">Cancel</button>
                                    <button v-if="!changeButton" @click="deleteBonetype(selectedBonetype.id)"
                                        class="btn btn-danger btn-sm mx-3">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <CountryData />
    <ExcavationData />
    <IndividualData />
    <PartData />
    <PlaceData />
    <ProvinceData />
    <SampleData />
    <SampleTypeData />
    <DatingData />
    <GenderData />
    <PeriodData />

</template>
<script>
import axios from "axios";
import $ from "jquery";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import "jspdf-autotable";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import CountryData from "./CountryData"
import ExcavationData from "./ExcavationData"
import IndividualData from "./IndividualData"
import PartData from "./PartData"
import PlaceData from './PlaceData'
import ProvinceData from './ProvinceData'
import SampleData from './SampleData'
import SampleTypeData from './SampleTypeData'
import DatingData from './DatingData'
import GenderData from './GenderData'
import PeriodData from './PeriodData'

export default {
    name: "TableData",
    components: {
        CountryData,
        ExcavationData,
        IndividualData,
        PartData,
        PlaceData,
        ProvinceData,
        SampleData,
        SampleTypeData,
        DatingData,
        GenderData,
        PeriodData
    },
    data() {
        return {
            bonetypes: [],
            selectedBonetype: { bonetype_id: null, bonetype_name: null, hammas: null },
            formDataVisible: false,
            changeButton: false,
            options: ['bonetype_name', 'bonetype_name', 'hammas', 'individual', 'part', 'place', 'province','sample','sampletype','dating','gender','period'],
      isOpen: false,
      selectedOptions: [],
      loadData:false,
            showDownloadToUser: false,
            showDownloadToEditor: false,
        };
    },
    async created() {
        await this.fetchData();
    },

    async mounted() {
        await this.fetchData();
    },

    methods: {
        async fetchData() {
            try {
                const productsResponse = await axios.get(
                    "https://sugrige.com/api/bonetype"
                );
                if (productsResponse.status === 200) {
                    this.bonetypes = productsResponse.data.bonetype;
                    this.initDataTable();
                    this.loadData= true;
                }
            } catch (error) {
                console.error("Error retrieving data:", error);
            }
        },
        getFilteredData() {
            const table = $('#dataTableSort').DataTable();
            return table.rows({ filter: 'applied' }).data().toArray();
        },

        downloadExcel() {
            let filteredData = this.getFilteredData();
            const worksheet = XLSX.utils.json_to_sheet(filteredData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, "bonetype_data.xlsx");
        },
        downloadPDF() {
            const doc = new jsPDF();
            let filteredData = this.getFilteredData();
            const data = [];

            filteredData.forEach(country => {
                const countryData = [];
                for (const key in country) {
                    if (Object.prototype.hasOwnProperty.call(country, key)) {
                        countryData.push(country[key]);
                    }
                }
                data.push(countryData);
            });

            const headers = ['bonetype_id', 'bonetype_name', 'hammas', ];

            doc.autoTable({
                head: [headers],
                body: data
            });

            doc.save('bonetype_data.pdf');
        },
        initDataTable() {
            $('#dataTableSort').DataTable();
        },

        selectBonetype(bonetype) {
            this.selectedBonetype = bonetype;
            this.formDataVisible = true;
        },
        deselectBonetype() {
            this.selectedBonetype = null;
            this.formDataVisible = false;
        },

        async addNewBonetype() {
            if (this.selectedBonetype.id !== null) {
                this.deselectBonetype();
            }
            this.formDataVisible = true;
            this.changeButton = true;
            this.selectedBonetype = {
                bonetype_id: null,
                bonetype_name: null,
                hammas: null
            };
        },
        async editBonetype() {
            try {
                const response = await axios.post("https://sugrige.com/api/save_bonetype", this.selectedBonetype);
                this.formDataVisible = false;
                setTimeout(() => {
                    window.location.reload();
                }, 1200);
                this.showToast(response.data.message);

            } catch (error) {
                if (error.response && error.response.data && error.response.data.message && error.response.data.errors) {
                    const errors = error.response.data.errors;
                    Object.values(errors).forEach(fieldErrors => {
                        fieldErrors.forEach(errorMessage => {
                            toast(errorMessage, {
                                autoClose: 1500,
                                type: "error",
                                position: "top-right",
                                pauseOnHover: true,
                                closeOnClick: false,
                            });
                        });
                    });
                } else {
                    console.error(error);
                    toast("An error .", {
                        autoClose: 1500,
                        type: "error",
                        position: "top-right",
                        pauseOnHover: true,
                        closeOnClick: false,
                    });
                }
            }
        },
        async deleteBonetype(id) {
            try {
                const response = await axios.delete(`https://sugrige.com/api/delete_bonetype/${id}`);
                this.bonetypes = this.bonetypes.filter(bonetype => bonetype.id !== id);
                this.formDataVisible = false;
                this.showToast(response.data.message);
                setTimeout(() => {
                    window.location.reload();
                }, 1200);
            } catch (error) {
                this.error = error.response.data.message;
                toast(error.response.data.message, {
                    autoClose: 1500,
                    type: "error",
                    position: "top-right",
                    pauseOnHover: true,
                    closeOnClick: false,
                });
            }
        },
        async cancelBonetype() {
            this.formDataVisible = false;
        },

        getRolePermission() {
            try {
                const rolePermissionString = localStorage.getItem("USERROLE");
                return rolePermissionString ? JSON.parse(rolePermissionString) : null;
            } catch (error) {
                console.error("Error parsing USERROLE:", error);
                return null;
            }
        },
        showToast(message) {
            toast(message, {
                autoClose: 1200,
                type: "success",
                position: "top-right",
                pauseOnHover: true,
                closeOnClick: false,
            });
        },
        toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.selectedOptions.push(option);
      this.isOpen = false;
      this.$emit('input', this.selectedOptions);
    },
    removeOption(option) {
      this.selectedOptions = this.selectedOptions.filter(opt => opt !== option);
      this.$emit('input', this.selectedOptions);
    }

    },
    computed: {
        filteredOptions() {
      return this.options.filter(option => !this.selectedOptions.includes(option));
    },
        isAdminAllowedToEditProduct() {
            const rolePermissionString = localStorage.getItem("USERROLE");
            const rolePermission = JSON.parse(rolePermissionString);
            return rolePermission && rolePermission.role.slug === "admin";
        },
        userRole() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug;
        },
        userLogin() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.user.name;
        },
        dataTableClass() {
            return {
                'col-md-7': this.formDataVisible,
                'col-md-12': !this.formDataVisible
            };
        },
        formTableClass() {
            return {
                'col-md-5 formTable': this.formDataVisible,
                'col-md-12 d-none': !this.formDataVisible
            };
        },
        isAdminAllowed() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "admin";
        },
        isEditorAllowed() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "editor";
        },
        isUserAllowedToShowButton() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "user" && rolePermission.settings.showDownloadToUser == 1;
         },
         isEditorAllowedToShowButton() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "editor" && rolePermission.settings.showDownloadToEditor == 1;
         }


    },



};
</script>


<style lang="scss" scoped>
table.dataTable th.dt-type-numeric,
table.dataTable th.dt-type-date,
table.dataTable td.dt-type-numeric,
table.dataTable td.dt-type-date {
    text-align: left;
}

.formTable {
    animation: fadeRight 1s 1;
}

@keyframes fadeRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0%);
    }
}

.multiselect {
    position: relative;
    min-width:200px;
    max-width:250px;
    padding:7px;

  }

  .multiselect-input {
    border: 1px solid #ccc;
    padding: 7px;
    display: flex;
overflow:auto;
    cursor: pointer;
    border-radius: 7px;
    color:#fff;
  }
  .multiselect-input::-webkit-scrollbar {
    height: 3px;
}


  .placeholder {
    color: #fff;
    font-weight:500;
  }

  .selected-option {
    background-color: rgb(12,100,143);
    padding: 2px 5px;
    margin: 2px;
    border-radius: 2px;
    display: flex;
    align-items: center;
  }

  .selected-option span {
    margin-left: 5px;
    cursor: pointer;
  }

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background-color: #fff;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000;
  }

  .dropdown-option {
    padding: 5px;
    cursor: pointer;
  }

  .dropdown-option:hover {
    background-color: #e3e3e3;
  }

</style>
