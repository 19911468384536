<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark rounded sticky-top" :class="{ 'bg-scrolled': isScrolled }">
        <div class="container-fluid">
            <router-link class="navbar-brand" to="/">Sugrige</router-link>
            <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false"
                aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse" id="navbarSupportedContent1">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item mx-1 component-li" v-for="component in components" :key="component.name">
                        <router-link :to="component.name + 'Data'" class="nav-link text-white">{{ component.name
                            }}</router-link>
                    </li>
                    <li class="nav-item mx-1" v-if="isAdminAllowed">
                        <router-link class="nav-link text-white" to="/UserRole">UserRole</router-link>
                    </li>
                    <li class="nav-item mx-1">
                        <router-link v-if="isLoggedIn" class="nav-link text-white" to="/login">Logout</router-link>
                        <router-link v-else class="nav-link text-white" to="/login">Login</router-link>
                    </li>
                    <li class="nav-item d-flex align-items-center ms-2" v-if="isLoggedIn">
                        <p class="mb-0 text-primary">Logged in as <span class="text-capitalize">{{ userLogin }}</span>
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

</template>



<script>
export default {
    name: "TopHeader",
    data() {
        return {
            isScrolled: false,

            components: [
                { name: "Country" },
                { name: "Excavation" },
                { name: "Individual" },
                { name: "Part" },
                { name: "Place" },
                { name: "Province" },
                { name: "Sample" },
                { name: "SampleType" },
                { name: "Dating" },
                { name: "Gender" },
                { name: "Period" }
            ]
        };
    },
    computed: {
        userRole() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug;
        },
        userLogin() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.user.name;
        },
        isLoggedIn() {
            return this.userLogin !== undefined && this.userLogin !== null;
        },
        isAdminAllowed() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "admin";
        },
    },
    methods: {
        getRolePermission() {
            try {
                const rolePermissionString = localStorage.getItem("USERROLE");
                return rolePermissionString ? JSON.parse(rolePermissionString) : null;
            } catch (error) {
                console.error("Error parsing USERROLE:", error);
                return null;
            }
        },
        handleScroll() {
            this.isScrolled = window.scrollY > 0;
        }
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Luxurious+Script&display=swap");

.navbar-brand {
    font-family: "Luxurious Script", cursive;
    font-size: 2rem !important;
    font-weight: 600;
}

.navbar.bg-dark.bg-scrolled {
    transition: background-color 0.3s ease;
    background-color: rgb(129, 129, 129) !important;
}

.navbar .container-fluid {
    max-width: 1400px;
    margin: auto;
}

.navbar p,
.navbar a {
    font-size: 14px;
}

@media screen and (max-width:1220px) {

    .navbar p,
    .navbar a {
        font-size: 12px;
    }
}

@media screen and (max-width:1120px) {

    .navbar p,
    .navbar a {
        font-size: 10px;
    }
}

@media screen and (max-width:991px) {

    .navbar p,
    .navbar a {
        font-size: 17px;
    }
}
</style>
