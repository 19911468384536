<template>
    <div>
        <TopHeader v-if="!isLoginPage && !isSignUpPage" />
        <router-view></router-view>
    </div>
</template>

<script>
import TopHeader from "./components/TopHeader.vue";

export default {
    name: "App",
    components: {
        TopHeader,
    },
    computed: {
        isLoginPage() {
            return this.$route.path === "/login";
        },
        isSignUpPage() {
            return this.$route.path === "/signUp";
        },
    },
};
</script>

<style>
body {
    overflow-x: hidden;
}

.formLabel {
    text-transform: capitalize;
}

.formTable .card {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, .2);

}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(173, 173, 173);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #7c7c7c;
}
</style>
