<template>
    <div class="container mt-5">
        <h2 class="mb-0 mt-3 text-uppercase">ProvinceData Table</h2>
        <hr />
        <div class="row">
            <div :class="dataTableClass">
                <div v-if="provinces.length">
                    <button v-if="isAdminAllowed || isEditorAllowed" class="btn btn-primary my-3" type="button"
                        @click="addNewProvince">Add new data</button>
                        <div class="d-flex gap-2 align-items-center mb-3" v-if="loadData">
                            <button class="btn btn-primary btn-sm" type="button" v-if="isUserAllowedToShowButton || isEditorAllowedToShowButton || isAdminAllowed " @click="downloadExcel">Download as Excel </button>
                            <button class="btn btn-primary btn-sm" type="button" v-if="isEditorAllowedToShowButton || isUserAllowedToShowButton || isAdminAllowed" @click="downloadPDF">Download as PDF </button>
                        </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table id="provinceTableSort" class="table table-striped table-bordered"
                                    style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>province_id</th>
                                            <th>province</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="cursor: pointer;" v-for="(province, index) in provinces" :key="index"
                                            valign="middle" @click="selectProvince(province)">
                                            <td>{{ province.province_id }}</td>
                                            <td>{{ province.province }}</td>

                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>province_id</th>
                                            <th>province</th>


                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4" v-else>No Data Found.</div>
            </div>
            <div :class="formTableClass" :style="{ marginTop: isAdminAllowed || isEditorAllowed ? '0.4rem' : '0' }">
                <div class="card border-top border-0 border-4 border-info" style="margin-top: 4rem;">
                    <div class="card-body">
                        <div class="border p-4 rounded">

                            <div class="row mb-3" v-for="(value, key) in selectedProvince" :key="key">
                                <div v-if="key !== 'id'">
                                    <label class="col-sm-3 col-form-label formLabel">{{ key }}</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" v-model="selectedProvince[key]">
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3 py-3" v-if="isAdminAllowed || isEditorAllowed">
                                <div>
                                    <button v-if="!changeButton" @click="editProvince" class="btn btn-warning btn-sm">
                                        Edit
                                    </button>
                                    <button v-if="changeButton" @click="editProvince"
                                        class="btn btn-primary mx-3 btn-sm">Save</button>
                                    <button @click="cancelProvince" class="btn btn-danger ms-3 btn-sm">Cancel</button>
                                    <button v-if="!changeButton" @click="deleteProvince(selectedProvince.id)"
                                        class="btn btn-danger mx-3 btn-sm">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>
<script>
import axios from "axios";
import $ from "jquery";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import "jspdf-autotable";
export default {
    name: "ProvinceData",

    data() {
        return {
            provinces: [],
            selectedProvince: {
                province_id: null,
                province: null,
            },
            formDataVisible: false,
            changeButton: false,
            loadData:false,
            showDownloadToUser: false,
            showDownloadToEditor: false,
        };
    },
    async created() {
        await this.fetchData();
    },

    async mounted() {
        await this.fetchData();
    },

    methods: {
        async fetchData() {
            try {
                const provinceResponse = await axios.get(
                    "https://sugrige.com/api/province"
                );
                if (provinceResponse.status === 200) {
                    this.provinces = provinceResponse.data.province;
                    this.initDataTable();
                    this.loadData= true;
                }
            } catch (error) {
                console.error("Error retrieving data:", error);
            }
        },
        getFilteredData() {
            const table = $('#provinceTableSort').DataTable();
            return table.rows({ filter: 'applied' }).data().toArray();
        },
        downloadExcel() {
            let filteredData = this.getFilteredData();
            const worksheet = XLSX.utils.json_to_sheet(filteredData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, "province_data.xlsx");
        },
        downloadPDF() {
            const doc = new jsPDF();
            let filteredData = this.getFilteredData();
            const data = [];

            filteredData.forEach(country => {
                const countryData = [];
                for (const key in country) {
                    if (Object.prototype.hasOwnProperty.call(country, key)) {
                        countryData.push(country[key]);
                    }
                }
                data.push(countryData);
            });

            const headers = ['province_id', 'province', ];

            doc.autoTable({
                head: [headers],
                body: data
            });

            doc.save('province_data.pdf');
        },
        initDataTable() {
            $('#provinceTableSort').DataTable();
        },

        selectProvince(province) {
            this.selectedProvince = province;
            this.formDataVisible = true;
        },
        deselectProvince() {
            this.selectedProvince = null;
            this.formDataVisible = false;
        },

        async addNewProvince() {
            if (this.selectedProvince.id !== null) {
                this.deselectProvince();
            }
            this.formDataVisible = true;
            this.changeButton = true;
            this.selectedProvince = {
                province_id: null,
                province: null,

            };
        },
        async editProvince() {
            try {
                const response = await axios.post("https://sugrige.com/api/save_province", this.selectedProvince);
                this.formDataVisible = false;
                setTimeout(() => {
                    window.location.reload();
                }, 1200);
                this.showToast(response.data.message);

            } catch (error) {
                if (error.response && error.response.data && error.response.data.message && error.response.data.errors) {
                    const errors = error.response.data.errors;
                    Object.values(errors).forEach(fieldErrors => {
                        fieldErrors.forEach(errorMessage => {
                            toast(errorMessage, {
                                autoClose: 1500,
                                type: "error",
                                position: "top-right",
                                pauseOnHover: true,
                                closeOnClick: false,
                            });
                        });
                    });
                } else {
                    console.error(error);
                    toast("An error .", {
                        autoClose: 1500,
                        type: "error",
                        position: "top-right",
                        pauseOnHover: true,
                        closeOnClick: false,
                    });
                }
            }
        },
        async deleteProvince(id) {
            try {
                const response = await axios.delete(`https://sugrige.com/api/delete_province/${id}`);
                this.provinces = this.provinces.filter(province => province.id !== id);
                this.formDataVisible = false;
                this.showToast(response.data.message);
                setTimeout(() => {
                    window.location.reload();
                }, 1200);
            } catch (error) {
                this.error = error.response.data.message;
                toast(error.response.data.message, {
                    autoClose: 1500,
                    type: "error",
                    position: "top-right",
                    pauseOnHover: true,
                    closeOnClick: false,
                });
            }
        },
        async cancelProvince() {
            this.formDataVisible = false;
        },

        getRolePermission() {
            try {
                const rolePermissionString = localStorage.getItem("USERROLE");
                return rolePermissionString ? JSON.parse(rolePermissionString) : null;
            } catch (error) {
                console.error("Error parsing USERROLE:", error);
                return null;
            }
        },
        showToast(message) {
            toast(message, {
                autoClose: 1200,
                type: "success",
                position: "top-right",
                pauseOnHover: true,
                closeOnClick: false,
            });
        },

    },
    computed: {
        isAdminAllowedToEditProduct() {
            const rolePermissionString = localStorage.getItem("USERROLE");
            const rolePermission = JSON.parse(rolePermissionString);
            return rolePermission && rolePermission.role.slug === "admin";
        },
        userRole() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug;
        },
        userLogin() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.user.name;
        },
        dataTableClass() {
            return {
                'col-md-7': this.formDataVisible,
                'col-md-12': !this.formDataVisible
            };
        },
        formTableClass() {
            return {
                'col-md-5 formTable': this.formDataVisible,
                'col-md-12 d-none': !this.formDataVisible
            };
        },
        isAdminAllowed() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "admin";
        },
        isEditorAllowed() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "editor";
        },
        isUserAllowedToShowButton() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "user" && rolePermission.settings.showDownloadToUser == 1;
         },
         isEditorAllowedToShowButton() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "editor" && rolePermission.settings.showDownloadToEditor == 1;
         }

    },
};
</script>


<style lang="scss" scoped>
table.dataTable th.dt-type-numeric,
table.dataTable th.dt-type-date,
table.dataTable td.dt-type-numeric,
table.dataTable td.dt-type-date {
    text-align: left;
}

.formTable {
    animation: fadeRight 1s 1;
}

@keyframes fadeRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0%);
    }
}

.formTable {
    .card {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, .2);

        .scroll-content {
            height: 700px;
            overflow-y: auto;
            overflow-x: hidden;
            margin-bottom: 1rem;
        }

    }

}
</style>
