<template>
    <div class="container my-4">
        <h1>Users Role</h1>

        <div class="card mt-3">
            <div class="card-body">
                <div class="mb-3 ms-auto" style="width: fit-content;">
                    <input style="width: 200px;" type="text" class="form-control" v-model="searchQuery"
                        placeholder="Search">
                </div>
                <div class="table-responsive">
                    <table class="table table-striped table-bordered" id="dataTableSort">
                        <thead>
                            <tr>
                                <th>User Name</th>
                                <th>User Email</th>
                                <th>User Role</th>
                                <th>Change User Role</th>
                                <th>Update Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(user, index) in filteredUsers" :key="index" class="align-middle">
                                <td>{{ user.name }}</td>
                                <td>{{ user.email }}</td>
                                <td>{{ user.roles.map(role => role.name).join(', ') }}</td>
                                <td>
                                    <select v-model="user.roles[0].pivot.role_id" class="form-select">
                                        <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.slug }}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <button @click="updateUserRole(user)" class="btn btn-primary" >
                                        Update Role
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="card mt-3">
            <div class="card-body">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="showDownloadToUser" >
                    <label class="form-check-label" >
                        Show Download Buttons to User

                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="showDownloadToEditor" >
                    <label class="form-check-label" >
                        Show Download Buttons to Editor
                    </label>
                </div>

                <button @click="saveSettings" class="btn btn-primary mt-3" type="submit" :disabled="isLoading">
                    Save
                    <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span></button>

            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";


export default {
    data() {
        return {
            users: [],
            roles: [],
            searchQuery: '',
            isLoading: false,
            showDownloadToUser: false,
            showDownloadToEditor: false
        };
    },
    computed: {
        filteredUsers() {
            if (!this.searchQuery) return this.users;
            return this.users.filter(user => {
                return user.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                    user.email.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                    user.roles.map(role => role.name.toLowerCase()).join(', ').includes(this.searchQuery.toLowerCase());
            });
        },

    },
    methods: {
        async updateUserRole(user) {
            try {
                const roleId = user.roles[0].pivot.role_id;
                await axios.put(`https://sugrige.com/api/users/${user.id}/role`, { role_id: roleId });
                await this.fetchUsers();
                // setTimeout(() => {
                //     // this.$router.push('/');
                //     window.location.reload();
                // }, 300);

                setTimeout(() => {
                    window.location.reload();
                }, 500);


            } catch (error) {
                console.error('Error updating user role:', error);
            }
        },
        async fetchUsers() {
            try {
                const response = await axios.get('https://sugrige.com/api/users');
                this.users = response.data.users;
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        },
        async fetchRoles() {
            try {
                const response = await axios.get('https://sugrige.com/api/roles');
                this.roles = response.data.roles;
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        },
        async fetchSettings() {
            try {
                const response = await axios.get('https://sugrige.com/api/settings');
                this.showDownloadToUser = response.data.showDownloadToUser === 1;
                this.showDownloadToEditor = response.data.showDownloadToEditor === 1;
            } catch (error) {
                console.error('Error fetching settings:', error);
            }
        },
        async saveSettings() {
            this.isLoading = true;

            try {
                const settings = {
                    showDownloadToUser: this.showDownloadToUser ? 1 : 0,
                    showDownloadToEditor: this.showDownloadToEditor ? 1 : 0
                };

                await axios.post('https://sugrige.com/api/settings', settings);
                this.isLoading = false;

                setTimeout(() => {
                    window.location.reload();
                }, 500);
            } catch (error) {
                console.error('Error saving settings:', error);
                this.isLoading = false;

            }
        }

    },
    created() {
        this.fetchUsers();
        this.fetchRoles();
        this.fetchSettings();
    }
};
</script>
