<template>
    <div class="container mt-5">
        <h2 class="mb-0 mt-3 text-uppercase">ExcavationData Table</h2>
        <hr />
        <div class="row">
            <div :class="dataTableClass">
                <div v-if="excavations.length">
                    <button v-if="isAdminAllowed || isEditorAllowed" class="btn btn-primary my-3" type="button"
                        @click="addNewExcavation">Add new data</button>
                        <div class="d-flex gap-2 align-items-center mb-3" v-if="loadData">
                            <button class="btn btn-primary btn-sm" type="button" v-if="isUserAllowedToShowButton || isEditorAllowedToShowButton || isAdminAllowed " @click="downloadExcel">Download as Excel </button>
                            <button class="btn btn-primary btn-sm" type="button" v-if="isEditorAllowedToShowButton || isUserAllowedToShowButton || isAdminAllowed" @click="downloadPDF">Download as PDF </button>
                        </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table id="excavationTableSort" class="table table-striped table-bordered"
                                    style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>excavation_id</th>
                                            <th>time_from</th>
                                            <th>time_to</th>
                                            <th>placeid</th>
                                            <th>date_created</th>
                                            <th>date_updated</th>
                                            <th>notes</th>
                                            <th>fullname</th>
                                            <th>person_id</th>
                                            <th>entrydate</th>
                                            <th>kmnumber</th>
                                            <th>othernro</th>
                                            <th>gotsamples</th>
                                            <th>applied</th>
                                            <th>tutkimuslupa</th>
                                            <th>rejected</th>
                                            <th>rejection_why</th>
                                            <th>about_n</th>
                                            <th>museourl</th>
                                            <th>institute</th>
                                            <th>pandora_id</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="cursor: pointer;" v-for="(excavation, index) in excavations"
                                            :key="index" valign="middle" @click="selectExcavation(excavation)">
                                            <td>{{ excavation.excavation_id }}</td>
                                            <td>{{ excavation.time_from }}</td>
                                            <td>{{ excavation.time_to }}</td>
                                            <td>{{ excavation.placeid }}</td>
                                            <td>{{ excavation.created_at }}</td>
                                            <td>{{ excavation.updated_at }}</td>
                                            <td>{{ excavation.notes }}</td>
                                            <td>{{ excavation.fullname }}</td>
                                            <td>{{ excavation.person_id }}</td>
                                            <td>{{ excavation.entrydate }}</td>
                                            <td>{{ excavation.kmnumber }}</td>
                                            <td>{{ excavation.othernro }}</td>
                                            <td>{{ excavation.gotsamples }}</td>
                                            <td>{{ excavation.applied }}</td>
                                            <td>{{ excavation.tutkimuslupa }}</td>
                                            <td>{{ excavation.rejected }}</td>
                                            <td>{{ excavation.rejection_why }}</td>
                                            <td>{{ excavation.about_n }}</td>
                                            <td>{{ excavation.museourl }}</td>
                                            <td>{{ excavation.institute }}</td>
                                            <td>{{ excavation.pandora_id }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>excavation_id</th>
                                            <th>time_from</th>
                                            <th>time_to</th>
                                            <th>placeid</th>
                                            <th>date_created</th>
                                            <th>date_updated</th>
                                            <th>notes</th>
                                            <th>fullname</th>
                                            <th>person_id</th>
                                            <th>entrydate</th>
                                            <th>kmnumber</th>
                                            <th>othernro</th>
                                            <th>gotsamples</th>
                                            <th>applied</th>
                                            <th>tutkimuslupa</th>
                                            <th>rejected</th>
                                            <th>rejection_why</th>
                                            <th>about_n</th>
                                            <th>museourl</th>
                                            <th>institute</th>
                                            <th>pandora_id</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4" v-else>No Data Found.</div>
            </div>
            <div :class="formTableClass" :style="{ marginTop: isAdminAllowed || isEditorAllowed ? '0.4rem' : '0' }">
                <div class="card border-top border-0 border-4 border-info" style="margin-top: 4rem;">
                    <div class="card-body">
                        <div class="border p-4 rounded">
                            <div class="scroll-content">

                                <div class="row mb-3" v-for="(value, key) in selectedExcavation" :key="key">
                                    <div v-if="key !== 'id'">
                                        <label class="col-sm-3 col-form-label formLabel">{{ key }}</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" v-model="selectedExcavation[key]"
                                                :readonly="['created_at', 'updated_at'].includes(key)">
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row mb-3 py-3" style="background-color:#f5f5f5"
                                v-if="isAdminAllowed || isEditorAllowed">
                                <div>
                                    <button v-if="!changeButton" @click="editExcavation" class="btn btn-warning btn-sm">
                                        Edit
                                    </button>
                                    <button v-if="changeButton" @click="editExcavation"
                                        class="btn btn-primary mx-3 btn-sm">Save</button>
                                    <button @click="cancelExcavation" class="btn btn-danger ms-3 btn-sm">Cancel</button>
                                    <button v-if="!changeButton" @click="deleteExcavation(selectedExcavation.id)"
                                        class="btn btn-danger mx-3 btn-sm">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>
<script>
import axios from "axios";
import $ from "jquery";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import "jspdf-autotable";
export default {
    name: "ExcavationData",

    data() {
        return {
            excavations: [],
            selectedExcavation: {
                excavation_id: null,
                time_from: null,
                time_to: null,
                placeid: null,
                created_at: null,
                updated_at: null,
                notes: null,
                fullname: null,
                person_id: null,
                entrydate: null,
                kmnumber: null,
                othernro: null,
                gotsamples: null,
                applied: null,
                tutkimuslupa: null,
                rejected: null,
                rejection_why: null,
                about_n: null,
                museourl: null,
                institute: null,
                pandora_id: null
            },
            formDataVisible: false,
            changeButton: false,
            loadData:false,
            showDownloadToUser: false,
            showDownloadToEditor: false,
        };
    },
    async created() {
        await this.fetchData();
    },

    async mounted() {
        await this.fetchData();
    },

    methods: {
        async fetchData() {
            try {
                const countryResponse = await axios.get(
                    "https://sugrige.com/api/excavation"
                );
                if (countryResponse.status === 200) {
                    this.excavations = countryResponse.data.excavation;
                    this.initDataTable();
                    this.loadData= true;
                }
            } catch (error) {
                console.error("Error retrieving data:", error);
            }
        },
        getFilteredData() {
            const table = $('#excavationTableSort').DataTable();
            return table.rows({ filter: 'applied' }).data().toArray();
        },
        downloadExcel() {
            let filteredData = this.getFilteredData();
            const worksheet = XLSX.utils.json_to_sheet(filteredData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, "excavation_data.xlsx");
        },
        downloadPDF() {
            const doc = new jsPDF();
            let filteredData = this.getFilteredData();
            const data = [];

            filteredData.forEach(country => {
                const countryData = [];
                for (const key in country) {
                    if (Object.prototype.hasOwnProperty.call(country, key)) {
                        countryData.push(country[key]);
                    }
                }
                data.push(countryData);
            });

            const headers = [
                "excavation_id",
                "time_from",
                "time_to",
                "placeid",
                "created_at",
                "updated_at",
                "notes",
                "fullname",
                "person_id",
                "entrydate",
                "kmnumber",
                "othernro",
                "gotsamples",
                "applied",
                "tutkimuslupa",
                "rejected",
                "rejection_why",
                "about_n",
                "museourl",
                "institute",
                "pandora_id"
            ];

            doc.autoTable({
                head: [headers],
                body: data
            });

            doc.save('excavation_data.pdf');
        },
        initDataTable() {
            $('#excavationTableSort').DataTable();
        },

        selectExcavation(bonetype) {
            this.selectedExcavation = bonetype;
            this.formDataVisible = true;
        },
        deselectExcavation() {
            this.selectedExcavation = null;
            this.formDataVisible = false;
        },

        async addNewExcavation() {
            if (this.selectedExcavation.id !== null) {
                this.deselectExcavation();
            }
            this.formDataVisible = true;
            this.changeButton = true;
            this.selectedExcavation = {
                excavation_id: null,
                time_from: null,
                time_to: null,
                placeid: null,
                created_at: null,
                updated_at: null,
                notes: null,
                fullname: null,
                person_id: null,
                entrydate: null,
                kmnumber: null,
                othernro: null,
                gotsamples: null,
                applied: null,
                tutkimuslupa: null,
                rejected: null,
                rejection_why: null,
                about_n: null,
                museourl: null,
                institute: null,
                pandora_id: null
            };
        },
        async editExcavation() {
            try {
                const response = await axios.post("https://sugrige.com/api/save_excavation", this.selectedExcavation);
                this.formDataVisible = false;
                setTimeout(() => {
                    window.location.reload();
                }, 1200);
                this.showToast(response.data.message);

            } catch (error) {
                if (error.response && error.response.data && error.response.data.message && error.response.data.errors) {
                    const errors = error.response.data.errors;
                    Object.values(errors).forEach(fieldErrors => {
                        fieldErrors.forEach(errorMessage => {
                            toast(errorMessage, {
                                autoClose: 1500,
                                type: "error",
                                position: "top-right",
                                pauseOnHover: true,
                                closeOnClick: false,
                            });
                        });
                    });
                } else {
                    console.error(error);
                    toast("An error .", {
                        autoClose: 1500,
                        type: "error",
                        position: "top-right",
                        pauseOnHover: true,
                        closeOnClick: false,
                    });
                }
            }
        },
        async deleteExcavation(id) {
            try {
                const response = await axios.delete(`https://sugrige.com/api/delete_excavation/${id}`);
                this.excavations = this.excavations.filter(excavation => excavation.id !== id);
                this.formDataVisible = false;
                this.showToast(response.data.message);
                setTimeout(() => {
                    window.location.reload();
                }, 1200);
            } catch (error) {
                this.error = error.response.data.message;
                toast(error.response.data.message, {
                    autoClose: 1500,
                    type: "error",
                    position: "top-right",
                    pauseOnHover: true,
                    closeOnClick: false,
                });
            }
        },
        async cancelExcavation() {
            this.formDataVisible = false;
        },

        getRolePermission() {
            try {
                const rolePermissionString = localStorage.getItem("USERROLE");
                return rolePermissionString ? JSON.parse(rolePermissionString) : null;
            } catch (error) {
                console.error("Error parsing USERROLE:", error);
                return null;
            }
        },
        showToast(message) {
            toast(message, {
                autoClose: 1200,
                type: "success",
                position: "top-right",
                pauseOnHover: true,
                closeOnClick: false,
            });
        },

    },
    computed: {
        isAdminAllowedToEditProduct() {
            const rolePermissionString = localStorage.getItem("USERROLE");
            const rolePermission = JSON.parse(rolePermissionString);
            return rolePermission && rolePermission.role.slug === "admin";
        },
        userRole() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug;
        },
        userLogin() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.user.name;
        },
        dataTableClass() {
            return {
                'col-md-7': this.formDataVisible,
                'col-md-12': !this.formDataVisible
            };
        },
        formTableClass() {
            return {
                'col-md-5 formTable': this.formDataVisible,
                'col-md-12 d-none': !this.formDataVisible
            };
        },
        isAdminAllowed() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "admin";
        },
        isEditorAllowed() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "editor";
        },
        isUserAllowedToShowButton() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "user" && rolePermission.settings.showDownloadToUser == 1;
         },
         isEditorAllowedToShowButton() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "editor" && rolePermission.settings.showDownloadToEditor == 1;
         }


    },
};
</script>


<style lang="scss" scoped>
table.dataTable th.dt-type-numeric,
table.dataTable th.dt-type-date,
table.dataTable td.dt-type-numeric,
table.dataTable td.dt-type-date {
    text-align: left;
}

.formTable {
    animation: fadeRight 1s 1;
}

@keyframes fadeRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0%);
    }
}

.formTable {
    .card {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, .2);

        .scroll-content {
            height: 700px;
            overflow-y: auto;
            overflow-x: hidden;
            margin-bottom: 1rem;
        }

    }

}
</style>
