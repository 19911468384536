<template>
    <div class="loginWrapper signUpWrapper">
        <div class="container">
            <div class="d-flex justify-content-center h-100">
                <div class="card">
                    <div class="card-body">
                        <form @submit.prevent="LoginUser" novalidate>
                            <div class="inputWrapper">
                                <div class="input-group form-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-envelope"></i></span>
                                    </div>
                                    <input type="email" class="form-control" placeholder="Your Email"
                                        v-model="formData.email" />
                                </div>
                                <p class="text-danger">
                                    {{ errors.email && errors.email.join(", ") }}
                                </p>
                            </div>
                            <div class="inputWrapper">
                                <div class="input-group form-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-key"></i></span>
                                    </div>
                                    <input type="password" class="form-control" placeholder="Password"
                                        v-model="formData.password" />
                                </div>
                                <p class="text-danger">
                                    {{ errors.password && errors.password.join(", ") }}
                                </p>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="login_btn btn" :disabled="isLoading">
                                    Login
                                    <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>

                            <div class="links d-flex justify-content-center mt-4">
                                <p>Not Registered</p>
                                <router-link class="nav-link p-0" to="/signUp">Sign Up</router-link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
    name: "LoginForm",
    data() {
        return {
            formData: {
                name: "",
                email: "",
                device_name: "browser",
            },
            errors: {},
            isLoading: false,
        };
    },
    methods: {
        LoginUser() {
            this.isLoading = true;

            axios
                .post("https://sugrige.com/api/login", this.formData)
                .then((res) => {
                    localStorage.setItem("TOKEN", res.data.token);
                    this.showToast("Login Successfully");
                    setTimeout(() => {
                        this.$router.push("/");
                        this.isLoading = false;
                    }, 120);
                })
                .catch((errors) => {
                    this.errors = errors.response.data.errors;
                    toast("Login Error", {
                        autoClose: 1000,
                        type: "error",
                        position: "top-right",
                        pauseOnHover: true,
                        closeOnClick: false,
                    })
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 1100);
                });
        },
        showToast(message) {
            toast(message, {
                autoClose: 1000,
                type: "success",
                position: "top-right",
                pauseOnHover: true,
                closeOnClick: false,
            });
        },
    },
};
</script>

<style>
.loginWrapper {
    background-image: url("http://getwallpapers.com/wallpaper/full/a/5/d/544750.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    position: absolute;
    top: 0;
    width: 100%;
}

.container {
    height: 100%;
    align-content: center;
}

.loginWrapper .card {
    height: 280px;
    width: 400px;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5) !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginWrapper .card .card-body {
    flex: 0;
    width: 100%;
}

.loginWrapper .inputWrapper {
    margin-bottom: 10px;
}

.loginWrapper .input-group {
    width: 100%;
    border-radius: 6px;
    border: 1px solid rgb(71, 71, 71);
}

.input-group-prepend span {
    width: 50px;
    background-color: #ffc312;
    color: black;
    border: 0 !important;
    height: 100%;
    border-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginWrapper .form-control {
    box-shadow: none;
    border: 0;
    outline: 0;
    font-size: 14px;
}

.loginWrapper .form-control:focus {
    outline: 0 !important;
    box-shadow: 0 !important;
}

.remember {
    color: white;
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
}

.remember input {
    width: 20px;
    height: 20px;
    margin-right: 6px;
}

.login_btn {
    color: black;
    background-color: #ffc312;
    width: 100%;
}

.login_btn:hover {
    color: black;
    background-color: white;
}

.links p {
    color: white;
    margin: 0;
}

.links .nav-link {
    color: rgb(72, 72, 248);
    text-decoration: underline;
    margin-left: 10px;
}

.loginWrapper .card-footer {
    padding: 0;
}

.login_btn .spinner-border {
    margin-right: 5px;
}
</style>
