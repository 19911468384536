<template>
    <div class="container mt-5">
        <h2 class="mb-0 mt-3 text-uppercase">DatingData Table</h2>
        <hr />
        <div class="row">
            <div :class="dataTableClass">
                <div v-if="dating.length">
                    <button v-if="isAdminAllowed || isEditorAllowed" class="btn btn-primary my-3" type="button"
                        @click="addNewDate">Add new data</button>
                        <div class="d-flex gap-2 align-items-center mb-3" v-if="loadData">
                            <button class="btn btn-primary btn-sm" type="button" v-if="isUserAllowedToShowButton || isEditorAllowedToShowButton || isAdminAllowed " @click="downloadExcel">Download as Excel </button>
                            <button class="btn btn-primary btn-sm" type="button" v-if="isEditorAllowedToShowButton || isUserAllowedToShowButton || isAdminAllowed" @click="downloadPDF">Download as PDF </button>
                        </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table id="dateTableSort" class="table table-striped table-bordered" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>Dating ID</th>
                                            <th>Sample ID</th>
                                            <th>Date Created</th>
                                            <th>Date Updated</th>
                                            <th>Notes</th>
                                            <th>Lab</th>
                                            <th>Who Paid</th>
                                            <th>Sample Sent</th>
                                            <th>Results Received</th>
                                            <th>Lab Code</th>
                                            <th>C14 Age BP</th>
                                            <th>Std Error</th>
                                            <th>Delta 13C AMS</th>
                                            <th>Cal 1 Sigma</th>
                                            <th>Cal 2 Sigma</th>
                                            <th>Mean</th>
                                            <th>Stdev</th>
                                            <th>C Percent</th>
                                            <th>C to N</th>
                                            <th>Collagen Percent</th>
                                            <th>Median</th>
                                            <th>Published In</th>
                                            <th>Material</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="cursor: pointer;" v-for="(date, index) in dating" :key="index"
                                            valign="middle" @click="selectDate(date)">
                                            <td>{{ date.datingid }}</td>
                                            <td>{{ date.sampleid }}</td>
                                            <td>{{ date.created_at }}</td>
                                            <td>{{ date.updated_at }}</td>
                                            <td>{{ date.notes }}</td>
                                            <td>{{ date.lab }}</td>
                                            <td>{{ date.whopaid }}</td>
                                            <td>{{ date.sample_sent }}</td>
                                            <td>{{ date.results_recieved }}</td>
                                            <td>{{ date.labcode }}</td>
                                            <td>{{ date.c14_age_bp }}</td>
                                            <td>{{ date.std_error }}</td>
                                            <td>{{ date.delta13c_ams }}</td>
                                            <td>{{ date.cal_1_sigma }}</td>
                                            <td>{{ date.cal_2_sigma }}</td>
                                            <td>{{ date.mean }}</td>
                                            <td>{{ date.stdev }}</td>
                                            <td>{{ date.c_percent }}</td>
                                            <td>{{ date.c_to_n }}</td>
                                            <td>{{ date.collagen_percent }}</td>
                                            <td>{{ date.median }}</td>
                                            <td>{{ date.published_in }}</td>
                                            <td>{{ date.material }}</td>


                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Dating ID</th>
                                            <th>Sample ID</th>
                                            <th>Date Created</th>
                                            <th>Date Updated</th>
                                            <th>Notes</th>
                                            <th>Lab</th>
                                            <th>Who Paid</th>
                                            <th>Sample Sent</th>
                                            <th>Results Received</th>
                                            <th>Lab Code</th>
                                            <th>C14 Age BP</th>
                                            <th>Std Error</th>
                                            <th>Delta 13C AMS</th>
                                            <th>Cal 1 Sigma</th>
                                            <th>Cal 2 Sigma</th>
                                            <th>Mean</th>
                                            <th>Stdev</th>
                                            <th>C Percent</th>
                                            <th>C to N</th>
                                            <th>Collagen Percent</th>
                                            <th>Median</th>
                                            <th>Published In</th>
                                            <th>Material</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4" v-else>No Data Found.</div>
            </div>
            <div :class="formTableClass" :style="{ marginTop: isAdminAllowed || isEditorAllowed ? '4.4rem' : '0' }">
                <div class="card border-top border-0 border-4 border-info" style="margin-top: 4rem;">
                    <div class="card-body">
                        <div class="border p-4 rounded">
                            <div class="scroll-content">

                                <div class="row mb-3" v-for="(value, key) in selectedDate" :key="key">
                                    <div v-if="key !== 'id'">
                                        <label class="col-sm-3 col-form-label formLabel">{{ key }}</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" v-model="selectedDate[key]"
                                                :readonly="['created_at', 'updated_at'].includes(key)">
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row mb-3 py-3" style="background-color:#f5f5f5"
                                v-if="isAdminAllowed || isEditorAllowed">
                                <div>
                                    <button v-if="!changeButton" @click="editDate" class="btn btn-warning btn-sm">
                                        Edit
                                    </button>
                                    <button v-if="changeButton" @click="editDate"
                                        class="btn btn-primary mx-3 btn-sm">Save</button>
                                    <button @click="cancelDate" class="btn btn-danger ms-3 btn-sm">Cancel</button>
                                    <button v-if="!changeButton" @click="deletDate(selectedDate.id)"
                                        class="btn btn-danger mx-3 btn-sm">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>
<script>
import axios from "axios";
import $ from "jquery";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import "jspdf-autotable";
export default {
    name: "DatingData",

    data() {
        return {
            dating: [],
            selectedDate: {
                datingid: null,
                sampleid: null,
                created_at: null,
                updated_at: null,
                notes: null,
                lab: null,
                whopaid: null,
                sample_sent: null,
                results_recieved: null,
                labcode: null,
                c14_age_bp: null,
                std_error: null,
                delta13c_ams: null,
                cal_1_sigma: null,
                cal_2_sigma: null,
                mean: null,
                stdev: null,
                c_percent: null,
                c_to_n: null,
                collagen_percent: null,
                median: null,
                published_in: null,
                material: null

            },
            formDataVisible: false,
            changeButton: false,
            loadData:false,
            showDownloadToUser: false,
            showDownloadToEditor: false,
        };
    },
    async created() {
        await this.fetchData();
    },

    async mounted() {
        await this.fetchData();
    },

    methods: {
        async fetchData() {
            try {
                const countryResponse = await axios.get(
                    "https://sugrige.com/api/dating"
                );
                if (countryResponse.status === 200) {
                    this.dating = countryResponse.data.date;
                    this.initDataTable();
                    this.loadData= true;
                }
            } catch (error) {
                console.error("Error retrieving data:", error);
            }
        },
        getFilteredData() {
            const table = $('#dateTableSort').DataTable();
            return table.rows({ filter: 'applied' }).data().toArray();
        },
        downloadExcel() {
            let filteredData = this.getFilteredData();
            const worksheet = XLSX.utils.json_to_sheet(filteredData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, "dateTable_data.xlsx");
        },
        downloadPDF() {
            const doc = new jsPDF();
            let filteredData = this.getFilteredData();
            const data = [];

            filteredData.forEach(country => {
                const countryData = [];
                for (const key in country) {
                    if (Object.prototype.hasOwnProperty.call(country, key)) {
                        countryData.push(country[key]);
                    }
                }
                data.push(countryData);
            });

            const headers = [
                "datingid",
                "sampleid",
                "created_at",
                "updated_at",
                "notes",
                "lab",
                "whopaid",
                "sample_sent",
                "results_recieved",
                "labcode",
                "c14_age_bp",
                "std_error",
                "delta13c_ams",
                "cal_1_sigma",
                "cal_2_sigma",
                "mean",
                "stdev",
                "c_percent",
                "c_to_n",
                "collagen_percent",
                "median",
                "published_in",
                "material"
            ];

            doc.autoTable({
                head: [headers],
                body: data
            });

            doc.save('dateTable_data.pdf');
        },
        initDataTable() {
            $('#dateTableSort').DataTable();
        },

        selectDate(date) {
            this.selectedDate = date;
            this.formDataVisible = true;
        },
        deselectDate() {
            this.selectedDate = null;
            this.formDataVisible = false;
        },

        async addNewDate() {
            if (this.selectedDate.id !== null) {
                this.deselectDate();
            }
            this.formDataVisible = true;
            this.changeButton = true;
            this.selectedDate = {
                datingid: null,
                sampleid: null,
                created_at: null,
                updated_at: null,
                notes: null,
                lab: null,
                whopaid: null,
                sample_sent: null,
                results_recieved: null,
                labcode: null,
                c14_age_bp: null,
                std_error: null,
                delta13c_ams: null,
                cal_1_sigma: null,
                cal_2_sigma: null,
                mean: null,
                stdev: null,
                c_percent: null,
                c_to_n: null,
                collagen_percent: null,
                median: null,
                published_in: null,
                material: null
            };
        },
        async editDate() {
            try {
                const response = await axios.post("https://sugrige.com/api/save_dating", this.selectedDate);
                this.formDataVisible = false;
                setTimeout(() => {
                    window.location.reload();
                }, 1200);
                this.showToast(response.data.message);

            } catch (error) {
                if (error.response && error.response.data && error.response.data.message && error.response.data.errors) {
                    const errors = error.response.data.errors;
                    Object.values(errors).forEach(fieldErrors => {
                        fieldErrors.forEach(errorMessage => {
                            toast(errorMessage, {
                                autoClose: 1500,
                                type: "error",
                                position: "top-right",
                                pauseOnHover: true,
                                closeOnClick: false,
                            });
                        });
                    });
                } else {
                    console.error(error);
                    toast("An error .", {
                        autoClose: 1500,
                        type: "error",
                        position: "top-right",
                        pauseOnHover: true,
                        closeOnClick: false,
                    });
                }
            }
        },
        async deletDate(id) {
            try {
                const response = await axios.delete(`https://sugrige.com/api/delete_dating/${id}`);
                this.dating = this.dating.filter(date => date.id !== id);
                this.formDataVisible = false;
                this.showToast(response.data.message);
                setTimeout(() => {
                    window.location.reload();
                }, 1200);
            } catch (error) {
                this.error = error.response.data.message;
                toast(error.response.data.message, {
                    autoClose: 1500,
                    type: "error",
                    position: "top-right",
                    pauseOnHover: true,
                    closeOnClick: false,
                });
            }
        },
        async cancelDate() {
            this.formDataVisible = false;
        },

        getRolePermission() {
            try {
                const rolePermissionString = localStorage.getItem("USERROLE");
                return rolePermissionString ? JSON.parse(rolePermissionString) : null;
            } catch (error) {
                console.error("Error parsing USERROLE:", error);
                return null;
            }
        },
        showToast(message) {
            toast(message, {
                autoClose: 1200,
                type: "success",
                position: "top-right",
                pauseOnHover: true,
                closeOnClick: false,
            });
        },

    },
    computed: {
        isAdminAllowedToEditProduct() {
            const rolePermissionString = localStorage.getItem("USERROLE");
            const rolePermission = JSON.parse(rolePermissionString);
            return rolePermission && rolePermission.role.slug === "admin";
        },
        userRole() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug;
        },
        userLogin() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.user.name;
        },
        dataTableClass() {
            return {
                'col-md-7': this.formDataVisible,
                'col-md-12': !this.formDataVisible
            };
        },
        formTableClass() {
            return {
                'col-md-5 formTable': this.formDataVisible,
                'col-md-12 d-none': !this.formDataVisible
            };
        },
        isAdminAllowed() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "admin";
        },
        isEditorAllowed() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "editor";
        },
        isUserAllowedToShowButton() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "user" && rolePermission.settings.showDownloadToUser == 1;
         },
         isEditorAllowedToShowButton() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "editor" && rolePermission.settings.showDownloadToEditor == 1;
         }

    },
};
</script>


<style lang="scss" scoped>
table.dataTable th.dt-type-numeric,
table.dataTable th.dt-type-date,
table.dataTable td.dt-type-numeric,
table.dataTable td.dt-type-date {
    text-align: left;
}

.formTable {
    animation: fadeRight 1s 1;
}

@keyframes fadeRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0%);
    }
}

.formTable {
    .card {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, .2);

        .scroll-content {
            height: 700px;
            overflow-y: auto;
            overflow-x: hidden;
            margin-bottom: 1rem;
        }

    }

}
</style>
