<template>
    <div class="container mt-5">
        <h2 class="mb-0 mt-3 text-uppercase">Country Table</h2>
        <hr />
        <div class="row">
            <div :class="dataTableClass">
                <div v-if="countries.length">
                    <button v-if="isAdminAllowed || isEditorAllowed" class="btn btn-primary my-3" type="button"
                        @click="addNewCountry">Add new data</button>
                        <div class="d-flex gap-2 align-items-center mb-3" v-if="loadData">
                            <button class="btn btn-primary btn-sm" type="button" v-if="isUserAllowedToShowButton || isEditorAllowedToShowButton || isAdminAllowed " @click="downloadExcel">Download as Excel </button>
                            <button class="btn btn-primary btn-sm" type="button" v-if="isEditorAllowedToShowButton || isUserAllowedToShowButton || isAdminAllowed" @click="downloadPDF">Download as PDF </button>
                        </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table id="countryTableSort" class="table table-striped table-bordered"
                                    style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>country_id</th>
                                            <th>country_name</th>
                                            <th>country_fi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="cursor: pointer;" v-for="(country, index) in countries" :key="index"
                                            valign="middle" @click="selectCountry(country)">
                                            <td>{{ country.country_id }}</td>
                                            <td>{{ country.country_name }}</td>
                                            <td>{{ country.country_fi }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>country_id</th>
                                            <th>country_name</th>
                                            <th>country_fi</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4" v-else>No Data Found.</div>
            </div>
            <div :class="formTableClass" :style="{ marginTop: isAdminAllowed || isEditorAllowed ? '4.4rem' : '0' }">
                <div class="card border-top border-0 border-4 border-info">
                    <div class="card-body">
                        <div class="border p-4 rounded">
                            <div class="row mb-3">
                                <label class="col-sm-3 col-form-label formLabel">Country id</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" v-model="selectedCountry.country_id">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label class="col-sm-3 col-form-label formLabel">Country name</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" v-model="selectedCountry.country_name">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label class="col-sm-3 col-form-label formLabel">Country fi</label>
                                <div class="col-sm-9">
                                    <input type="email" class="form-control" v-model="selectedCountry.country_fi">
                                </div>
                            </div>
                            <div class="row mb-3" v-if="isAdminAllowed || isEditorAllowed">
                                <div class="col-sm-9 offset-sm-3">
                                    <button v-if="!changeButton" @click="editCountry"
                                        class="btn btn-warning btn-sm">Edit</button>
                                    <button v-if="changeButton" @click="editCountry"
                                        class="btn btn-primary mx-3 btn-sm">Save</button>
                                    <button @click="cancelCountry" class="btn btn-danger ms-3 btn-sm">Cancel</button>
                                    <button v-if="!changeButton" @click="deleteCountry(selectedCountry.id)"
                                        class="btn btn-danger mx-3 btn-sm">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



</template>
<script>
import axios from "axios";
import $ from "jquery";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import "jspdf-autotable";


export default {
    name: "CountryData",

    data() {
        return {
            countries: [],
            selectedCountry: { country_id: null, country_name: null, country_fi: null },
            formDataVisible: false,
            changeButton: false,
            loadData:false,
            showDownloadToUser: false,
            showDownloadToEditor: false,
        };
    },
    async created() {
        await this.fetchData();
        this.fetchSettings();


    },

    async mounted() {
        await this.fetchData();
    },

    methods: {
        async fetchData() {
            try {
                const countryResponse = await axios.get(
                    "https://sugrige.com/api/country"
                );
                if (countryResponse.status === 200) {
                    this.countries = countryResponse.data.country;
                    this.initDataTable();
                    this.loadData= true;
                }
            } catch (error) {
                console.error("Error retrieving data:", error);
            }
        },
        async fetchSettings() {
            try {
                const response = await axios.get('https://sugrige.com/api/settings');
                this.showDownloadToUser = response.data.showDownloadToUser === 1;
                this.showDownloadToEditor = response.data.showDownloadToEditor === 1;
            } catch (error) {
                console.error('Error fetching settings:', error);
            }
        },
        getFilteredData() {
            const table = $('#countryTableSort').DataTable();
            return table.rows({ filter: 'applied' }).data().toArray();
        },

        downloadExcel() {
            let filteredData = this.getFilteredData();
            const worksheet = XLSX.utils.json_to_sheet(filteredData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, "country_data.xlsx");
        },
        downloadPDF() {
            const doc = new jsPDF();
            let filteredData = this.getFilteredData();
            const data = [];

            filteredData.forEach(country => {
                const countryData = [];
                for (const key in country) {
                    if (Object.prototype.hasOwnProperty.call(country, key)) {
                        countryData.push(country[key]);
                    }
                }
                data.push(countryData);
            });

            const headers = ['country_id', 'country_name', 'country_fi'];
            doc.autoTable({
                head: [headers],
                body: data
            });

            doc.save('country_data.pdf');
        },

        initDataTable() {
            $('#countryTableSort').DataTable();
        },

        selectCountry(bonetype) {
            this.selectedCountry = bonetype;
            this.formDataVisible = true;
        },
        deselectCountry() {
            this.selectedCountry = null;
            this.formDataVisible = false;
        },

        async addNewCountry() {
            if (this.selectedCountry.id !== null) {
                this.deselectCountry();
            }
            this.formDataVisible = true;
            this.changeButton = true;
            this.selectedCountry = {
                country_id: null,
                country_name: null,
                country_fi: null
            };
        },
        async editCountry() {
            try {
                const response = await axios.post("https://sugrige.com/api/save_country", this.selectedCountry);
                this.formDataVisible = false;
                setTimeout(() => {
                    window.location.reload();
                }, 1200);
                this.showToast(response.data.message);

            } catch (error) {
                if (error.response && error.response.data && error.response.data.message && error.response.data.errors) {
                    const errors = error.response.data.errors;
                    Object.values(errors).forEach(fieldErrors => {
                        fieldErrors.forEach(errorMessage => {
                            toast(errorMessage, {
                                autoClose: 1500,
                                type: "error",
                                position: "top-right",
                                pauseOnHover: true,
                                closeOnClick: false,
                            });
                        });
                    });
                } else {
                    console.error(error);
                    toast("An error .", {
                        autoClose: 1500,
                        type: "error",
                        position: "top-right",
                        pauseOnHover: true,
                        closeOnClick: false,
                    });
                }
            }
        },
        async deleteCountry(id) {
            try {
                const response = await axios.delete(`https://sugrige.com/api/delete_country/${id}`);
                this.countries = this.countries.filter(country => country.id !== id);
                this.formDataVisible = false;
                this.showToast(response.data.message);
                setTimeout(() => {
                    window.location.reload();
                }, 1200);
            } catch (error) {
                this.error = error.response.data.message;
                toast(error.response.data.message, {
                    autoClose: 1500,
                    type: "error",
                    position: "top-right",
                    pauseOnHover: true,
                    closeOnClick: false,
                });
            }
        },
        async cancelCountry() {
            this.formDataVisible = false;
        },

        getRolePermission() {
            try {
                const rolePermissionString = localStorage.getItem("USERROLE");
                return rolePermissionString ? JSON.parse(rolePermissionString) : null;
            } catch (error) {
                console.error("Error parsing USERROLE:", error);
                return null;
            }
        },
        showToast(message) {
            toast(message, {
                autoClose: 1200,
                type: "success",
                position: "top-right",
                pauseOnHover: true,
                closeOnClick: false,
            });
        },

    },
    computed: {
        isAdminAllowedToEditProduct() {
            const rolePermissionString = localStorage.getItem("USERROLE");
            const rolePermission = JSON.parse(rolePermissionString);
            return rolePermission && rolePermission.role.slug === "admin";
        },
        userRole() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug;
        },
        userLogin() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.user.name;
        },
        dataTableClass() {
            return {
                'col-md-7': this.formDataVisible,
                'col-md-12': !this.formDataVisible
            };
        },
        formTableClass() {
            return {
                'col-md-5 formTable': this.formDataVisible,
                'col-md-12 d-none': !this.formDataVisible
            };
        },
        isAdminAllowed() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "admin";
        },
        isEditorAllowed() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "editor";
        },
        isUserAllowedToShowButton() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "user" && rolePermission.settings.showDownloadToUser == 1;
         },
         isEditorAllowedToShowButton() {
            const rolePermission = this.getRolePermission();
            return rolePermission && rolePermission.role.slug === "editor" && rolePermission.settings.showDownloadToEditor == 1;
         }

    },



};
</script>


<style lang="scss" scoped>
table.dataTable th.dt-type-numeric,
table.dataTable th.dt-type-date,
table.dataTable td.dt-type-numeric,
table.dataTable td.dt-type-date {
    text-align: left;
}

.formTable {
    animation: fadeRight 1s 1;
}

@keyframes fadeRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0%);
    }
}
</style>
