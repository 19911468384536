<template>
    <div class="loginWrapper signUpWrapper">
        <div class="container">
            <div class="d-flex justify-content-center h-100">
                <div class="card">
                    <div class="card-body">
                        <form @submit.prevent="registrationUser" novalidate>
                            <div class="inputWrapper">
                                <div class="input-group form-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-user"></i></span>
                                    </div>
                                    <input type="text" class="form-control" placeholder="Your Name"
                                        v-model="formData.name" />
                                </div>
                                <p class="text-danger">
                                    {{ errors.name && errors.name.join(", ") }}
                                </p>
                            </div>
                            <div class="inputWrapper">
                                <div class="input-group form-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-envelope"></i></span>
                                    </div>
                                    <input type="email" class="form-control" placeholder="Your Email"
                                        v-model="formData.email" />
                                </div>
                                <p class="text-danger">
                                    {{ errors.email && errors.email.join(", ") }}
                                </p>
                            </div>
                            <div class="inputWrapper">
                                <div class="input-group form-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-key"></i></span>
                                    </div>
                                    <input type="password" class="form-control" placeholder="Password"
                                        v-model="formData.password" />
                                </div>
                                <p class="text-danger">
                                    {{ errors.password && errors.password.join(", ") }}
                                </p>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="login_btn btn" :disabled="isLoading">
                                    Sign Up
                                    <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
    name: "SignUpForm",
    data() {
        return {
            formData: {
                name: "",
                email: "",
                password: "",
            },
            errors: {},
            isLoading: false,
        };
    },
    methods: {
        registrationUser() {
            this.isLoading = true;

            axios
                .post("https://sugrige.com/api/register", this.formData)
                .then((response) => {
                    response.data;
                    this.formData.name = "";
                    this.formData.email = "";
                    this.formData.password = "";
                    this.errors = {};
                    this.showToast("Registration successful!");
                    setTimeout(() => {
                        this.$router.push("/login");
                        this.isLoading = false;
                    }, 1200);
                })
                .catch((errors) => {
                    this.errors = errors.response.data.errors;
                    toast("Registration Failed", {
                        autoClose: 1000,
                        type: "error",
                        position: "top-right",
                        pauseOnHover: true,
                        closeOnClick: false,
                    });
                })
            setTimeout(() => {
                this.isLoading = false;
            }, 1100);
        },
        showToast(message) {
            toast(message, {
                autoClose: 1000,
                type: "success",
                position: "top-right",
                pauseOnHover: true,
                closeOnClick: false,
            });
        },
    },
};
</script>

<style scoped>
.loginWrapper .inputWrapper {
    margin-bottom: 10px;
}

#errorMessage {
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
}

.login_btn .spinner-border {
    margin-right: 5px;
}
</style>
