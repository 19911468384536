import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import UserRole from "../components/UserRole.vue";
import TableData from "../components/TableData.vue";
import LoginForm from "../components/LoginForm.vue";
import signUpForm from "../components/SignUpForm.vue";
import NotFound from "../components/NotFound.vue";
import CountryData from "../components/CountryData";
import ExcavationData from "../components/ExcavationData";
import IndividualData from "../components/IndividualData";
import PartData from "../components/PartData";
import PlaceData from "../components/PlaceData";
import ProvinceData from "../components/ProvinceData";
import SampleData from "../components/SampleData";
import SampleTypeData from "../components/SampleTypeData";
import DatingData from "../components/DatingData";
import GenderData from "../components/GenderData";
import PeriodData from "../components/PeriodData";

const routes = [
    { path: "/", component: TableData, meta: { requiresAuth: true } },
    { path: "/login", component: LoginForm },
    { path: "/signUp", component: signUpForm },
    { path: "/UserRole", component: UserRole, meta: { requiresAuth: true, requiresAdmin: true } },
    {
        path: "/:notFound(.*)",
        component: NotFound,
    },
    {
        path: "/CountryData",
        name: "CountryData",
        component: CountryData,
        meta: { requiresAuth: true },
    },
    {
        path: "/ExcavationData",
        name: "ExcavationData",
        component: ExcavationData,
        meta: { requiresAuth: true },
    },
    {
        path: "/IndividualData",
        name: "IndividualData",
        component: IndividualData,
        meta: { requiresAuth: true },
    },
    {
        path: "/PartData",
        name: "PartData",
        component: PartData,
        meta: { requiresAuth: true },
    },
    {
        path: "/PlaceData",
        name: "PlaceData",
        component: PlaceData,
        meta: { requiresAuth: true },
    },
    {
        path: "/ProvinceData",
        name: "ProvinceData",
        component: ProvinceData,
        meta: { requiresAuth: true },
    },
    {
        path: "/SampleData",
        name: "SampleData",
        component: SampleData,
        meta: { requiresAuth: true },
    },
    {
        path: "/SampleTypeData",
        name: "SampleTypeData",
        component: SampleTypeData,
        meta: { requiresAuth: true },
    },
    {
        path: "/DatingData",
        name: "DatingData",
        component: DatingData,
        meta: { requiresAuth: true },
    },
    {
        path: "/GenderData",
        name: "GenderData",
        component: GenderData,
        meta: { requiresAuth: true },
    },
    {
        path: "/PeriodData",
        name: "PeriodData",
        component: PeriodData,
        meta: { requiresAuth: true },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem("TOKEN");
    if (to.meta.requiresAuth) {
        if (token) {
            axios
                .get("https://sugrige.com/api/verifyToken", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    const userRole = {
                        user: res.data.user,
                        role: res.data.role,
                        settings: res.data.settings,
                    };
                    localStorage.setItem("USERROLE", JSON.stringify(userRole));
                    if (userRole.role.slug === "admin") {
                        router.addRoute({
                            path: "/UserRole",
                            component: UserRole,
                            meta: { requiresAuth: true },
                        });
                    }
                    next();
                })
                .catch(() => {
                    next("/login");
                });
        } else {
            next("/login");
        }
    } else {
        next();
    }
});

export default router;
